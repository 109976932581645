body {
    font-size: 14px;
    color: #333;
    background-color: #fff;
    height: auto;
    max-height: 100%;
    margin: 0;
    padding: 0;
}
#landing-page{
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
}
#landing-page:after{
    clear: both;
}
#landing-page img {
    vertical-align: middle;
    border: 0;
}
#landing-page:before, #landing-page:after {
    content: " ";
    display: table;
}
#landing-page .header{
    margin-top: 16px;
    max-height: 100px;
    position: relative;
}
#landing-page .header-logo img{
    height:32px;
}
#landing-page .container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
#landing-page .embed-responsive {
    position: relative;
    display: block;
    padding: 0;
    overflow: hidden;
    height: 487.13px;
}
#landing-page iframe{
    position: relative; height: 100%; width: 100%;
}
#landing-page .container #iframe-container{
    display: flex;
    flex-direction: column;
}
@media (min-width: 768px){
    #landing-page .container{
        max-width: 1024px;
        width: 750px;
    }
    #landing-page .container #iframe-container .iframe-video-container {
        margin: 45px 5%;
    }
    #landing-page .container #iframe-container footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }
    #landing-page .container #iframe-container footer .socmed-wrapper a img {
        height: 32px;
        margin-left: 16px;
    }
    #landing-page .embed-responsive {
        height:575px;
    }
    #landing-page .container #iframe-container footer p{
        color: #000;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: .58px;
        line-height: 17px;
        flex: 1 1;
        margin: 0;
    }
}
@media (min-width: 1200px){
    #landing-page .container {
        width: 978px;
    }
    #landing-page .embed-responsive {
        height:487.13px;
    }
}

@media (min-width: 992px){
    #landing-page .container {
        width: 978px;
    }
    #landing-page .embed-responsive {
        height:487.13px;
    }
}
@media (min-width: 480px) and (max-width: 767px){
    #landing-page .container #iframe-container .iframe-video-container {
        margin: 100px 0;
        flex: 1 1;
    }
    #landing-page .embed-responsive {
        height:600px;
    }
    #landing-page .container #iframe-container footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    #landing-page .container #iframe-container footer p{
        color: #000;
        flex: 1 1;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: .49px;
        line-height: 15px;
        text-align: center;
        margin: 10px 0 0;
    }
    #landing-page .container #iframe-container footer .socmed-wrapper a img {
        height: 32px;
        margin-left: 16px;
    }
}

