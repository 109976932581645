body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.form-control {
  margin-bottom: 10px;
}

table.table-bordered.dataTable th {
  text-align: center;
}

.show-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.show-scroll::-webkit-scrollbar:vertical {
  width: 11px;
}

.show-scroll::-webkit-scrollbar:horizontal {
  height: 11px;
}

.show-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

.show-scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
